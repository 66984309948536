<template>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
    <div class="card">
      <div class="card-header">Change Password</div>
      <div class="card-body">
        <v-form ref="form" lazy-validation v-model="valid">
          <div class="personal-form-info">
            <div class="row">
              <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                <div class="form-group">
                  <label class="control-label pb-1" for="phone">Current Password</label>
                  <v-text-field name="current_password" type="password" v-model="update.password" :rules="passwordRules" outlined :error-messages="auth_msg"></v-text-field>
                </div>
              </div> -->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                <div class="form-group">
                  <label class="control-label pb-1" for="phone">New Password</label>
                  <v-text-field name="new_password" type="password" v-model="update.new_password" :rules="passwordRules" outlined></v-text-field>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                <div class="form-group">
                  <label class="control-label pb-1" for="phone">Repeat Password</label>
                  <v-text-field name="repeat_password" type="password" v-model="update.new_password_confirmation" :rules="passwordConfirmationRules" outlined></v-text-field>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0">
                <a v-on:click="changePassword" class="btn btn-success btn-block text-white w-100">Save Changes</a>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Updated Succussfully</div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name:'settings',
  props:["student"],
  data(){
    return {
      valid:false,
      update:{
        // password:null,
        new_password:null,
        new_password_confirmation:null
      },
      auth_msg:'',
      passwordRules:[
        v => !!v || 'Enter valid password',
        v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      passwordConfirmationRules:[
        v => !!v || 'Enter valid password',
        v => this.update.new_password == v || 'Password not match'
      ]
    }
  },
  methods:{
    submitResetForm(e){
      e.preventDefault();
      this.reset_valid = this.$refs.reset_form.validate();
      if(this.reset_valid){
        this.$refs['confirm-modal'].show();
      }
    },
    changePassword(e){
      e.preventDefault();
      this.auth_msg = '';
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        axios.put(process.env.VUE_APP_TUTION_SITE_API+'/password/'+this.student.user.user_id,this.update,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          if(res.data.message == 'Password reset successfully'){
            this.$refs["success"].show();
            setTimeout(() => {
              this.$refs["success"].hide();
            },2000)
          }else{
            console.log('error');
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px !important;
    color: #5f5e61;
    line-height: 2.3;
}
</style>